var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "zadi-books-page"
  }, [_c('page-header', {
    attrs: {
      "title": "كنوز زادي"
    }
  }), _c('infinite-scroll-wrapper', {
    attrs: {
      "limit": _vm.limit,
      "total": _vm.total,
      "is-loading": _vm.isLoadingBooks
    },
    on: {
      "fetch-data": _vm.fetchAllBooks
    }
  }, [_vm._l(_vm.books, function (book) {
    return _c('book-summary-mini', {
      key: book.id,
      attrs: {
        "book": book
      }
    });
  }), _vm.isLoadingBooks ? _vm._l(_vm.limit, function (n) {
    return _c('book-summary-mini-skelton', {
      key: n
    });
  }) : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }